import React, { useEffect } from "react";
import { Box, Paper, styled, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DepositInterface from "./deposit";
import WithdrawInterface from "./withdraw";
import { Translate } from "react-redux-i18n";
import { useDispatch } from "react-redux";
import { getUserPaymentDetailsAsync } from "../../../redux-store/account-reducer";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const StyledContainer = styled(Paper)(({ theme }) => ({
  background: theme.palette.background.dbPaper,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    width: "70%",
    margin: "auto",
  },
  [theme.breakpoints.up("lg")]: {
    width: "60%",
    margin: "auto",
  },
}));

const UserPaymentsPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const tabValue = searchParams.get("interface");

  const handleChange = (event, newValue) => {
    searchParams.set("interface", newValue);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  useEffect(() => {
    dispatch(getUserPaymentDetailsAsync());
  }, []);

  return (
    <Box px={1} py={2}>
      <StyledContainer>
        <TabContext value={tabValue || "deposit"}>
          <Box pb={3}>
            <TabList indicatorColor="none" onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={<Translate value="menu.deposit" />} value="deposit" />
              <Tab label={<Translate value="menu.withdraw" />} value="withdraw" />
            </TabList>
          </Box>
          <TabPanel value="deposit">
            <DepositInterface />
          </TabPanel>
          <TabPanel value="withdraw">
            <WithdrawInterface />
          </TabPanel>
        </TabContext>
      </StyledContainer>
    </Box>
  );
};

export default UserPaymentsPage;
