import { Box, Button, Stack, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import RHFormProvider from "../../hook-form/form-provider";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "../../iconify";
import { Link } from "react-router-dom";
import { makeWithdrawAsync } from "../../../redux-store/account-reducer";
import RHFAmountField from "../../hook-form/rhf-amount-field";
import FreeSpinsAlert from "./free-spins-alert";
import { useState } from "react";

const WithdrawInterface = () => {
  const [shouldAlert, setAlert] = useState(false);
  const dispatch = useDispatch();
  // selectros
  const locale = useSelector((state) => state.i18n.locale);
  const translate = useSelector((state) => state.i18n.translations[locale]);
  const isLoading = useSelector((state) => state.account.payments.withdraw.isLoading);
  const balance = useSelector((state) => state.account.balanceDetails);
  // validation scheme
  const amountValidationScheme = Yup.object().shape({
    amount: Yup.number().min(25, translate.labels.maximum_withdraw).max(5000, translate.labels.maximum_withdraw).required(translate.labels.maximum_withdraw),
  });
  // RHF methods
  const methods = useForm({
    resolver: yupResolver(amountValidationScheme),
    defaultValues: {
      amount: 0,
    },
  });
  // Handle INIT DEPOSIT
  const onSubmit = async (formValues) => {
    dispatch(makeWithdrawAsync({ ...formValues }));
    setAlert(false);
    methods.setValue("amount", 0);
  };

  const handleAlert = () => {
    setAlert(!shouldAlert);
  };

  return (
    <RHFormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      <Stack alignItems="center" mb={3} gap={2}>
        <Box height="1.5em" component="img" src="https://api.nubet.com/media/icons/trustly.svg" />
        <Typography variant="body1" fontSize={24}>
          {translate.labels.withdraw_trustly_1}
          <Typography variant="h1" component="spin" color="primary">
            {translate.labels.withdraw_trustly_2}
          </Typography>
          {translate.labels.withdraw_trustly_3}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="center" gap={1} mb={3}>
        <Button onClick={() => methods.setValue("amount", 50, true)} endIcon={<Iconify icon="bx:euro" />} variant="contained" color="primary">
          50
        </Button>
        <Button onClick={() => methods.setValue("amount", 100, true)} endIcon={<Iconify icon="bx:euro" />} variant="contained" color="primary">
          100
        </Button>
        <Button onClick={() => methods.setValue("amount", 200, true)} endIcon={<Iconify icon="bx:euro" />} variant="contained" color="primary">
          200
        </Button>
      </Stack>
      <Stack alignItems="center" width={250} margin="auto">
        <RHFAmountField size="small" type="number" name="amount" />
      </Stack>
      <Stack mt={3} alignItems="center">
        <LoadingButton onClick={balance?.promo > 0 ? handleAlert : methods.handleSubmit(onSubmit)} loading={isLoading} startIcon={<Iconify icon="ph:hand-withdraw-bold" />} variant="contained" color="warning">
          {translate.labels.withdraw_title}
        </LoadingButton>
      </Stack>
      <Stack mt={3}>
        <Typography variant="body1" align="center">
          {translate.labels.deposit_terms_1}
          <Typography variant="body1" color="primary" component="spin">
            <Link to={`/${locale}/info/terms-conditions`}>{translate.labels.deposit_terms_2}</Link>
          </Typography>
        </Typography>
      </Stack>
      <FreeSpinsAlert open={shouldAlert} handleAlert={handleAlert} onConfirm={methods.handleSubmit(onSubmit)} />
    </RHFormProvider>
  );
};

export default WithdrawInterface;
